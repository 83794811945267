input::placeholder {
  color: darkgray; /* Change this to your desired color */
  opacity: 1; /* Ensure the placeholder is fully visible */
}

input:focus::placeholder {
  color: darkgray; /* Change color when the input is focused */
  opacity: 1;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  position: relative;  /* Ensures that the footer can be absolutely positioned relative to this container */
  box-sizing: border-box; /* Include padding and borders in the element's total width and height */
}

.app-main {
  flex: 1;
  overflow-y: auto;  /* Scroll inside main content area if needed */
  padding-bottom: 100px;
}

.app-footer {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%; /* Ensures footer extends full width of the viewport */
  min-height: 40px; /* Ensures the footer has enough space for its content */
  background-color: #f8f9fa; /* Example background color, change as needed */
  box-shadow: 0 -3px 5px rgba(0, 0, 0, 0.1); /* Shadow only at the top */
  z-index: 10; /* Ensures footer stays on top of other content */
  display: flex;
  justify-content: space-between; /* Space between children */
  align-items: center; /* Align children vertically */
  padding: 10px 20px; /* Adds padding around content */
}

.iub__us-widget {
  background-color: #f8f9fa; /* Example background color, change as needed */
}

.app-footer__inner {
  display: flex;
  justify-content: space-between; /* Space between center and right sections */
  align-items: center; /* Vertically align content */
  padding: 10px 20px; /* Adjust spacing as needed */
  width: 100%;
}

.app-footer .app-footer__inner .app-footer-left {
  flex: 1; /* Ensures center occupies available space */
  text-align: left;
}

.app-footer .app-footer__inner .app-footer-center {
  flex: 1; /* Ensures center occupies available space */
  text-align: center;
}

.app-footer .app-footer__inner .app-footer-right {
  flex: 1; /* Ensures equal space allocation */
  text-align: right; /* Aligns links to the right */
  margin-left: 10px; /* Adds spacing between links */
  text-decoration: none;
  display: inline-block;
}

.input-group-container {
  display: flex;
  align-items: center;  /* Ensures vertical alignment is centered */
  justify-content: space-between;  /* Pushes the child elements to either end */
  gap: 10px;  /* Optional: Provides space between children if they wrap */
}

.input-group-right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
}

.input-group-left {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}

  
  .input-250px {
	width: 250px;
  }
  
  .header-container {
	/*display: flex;*/
	justify-content: space-between;
	align-items: center;
	height: 60px;
	margin-bottom: 10px;
  }
  
  .card-body h4 {
	margin: 20px 0;
  }

  .a-bit-smaller {
	font-size: 1.65rem;
  }

  /* Header Dropdowns */
  .dropdown-menu {
    width: 19rem;
    padding: 1rem;
  }

  .dropdown-menu .nav-item.nav-item-header {
    text-transform: none;
    font-size: 0.88rem;
    padding-top: 0;
    font-weight: normal;
  }

  /* Sidebar Menu Classes */
  .metis-hidden ul.metismenu-container li.metismenu-item a.metismenu-link {
    padding-left: 12px;
  }

  /* Sidebar Menu Classes */
  .metis-hidden ul.metismenu-container li.metismenu-item a.metismenu-link i.metismenu-icon {
    display: none;
  }

/* Red/Green background animations for table cell updates */
  .dddatatable tr td, .meta-card {
	transition: background-color .5s ease-in-out;
  }

  .dddatatable > tbody > tr:not(.expanded-row) > td:has(.cell-transition-success), .meta-card.cell-transition-success {
	  background-color: green;
  }
	
  .dddatatable > tbody > tr:not(.expanded-row) > td:has(.cell-transition-failure), .meta-card.cell-transition-failure {
	  background-color: red;
  }

/* Card styles for meta cards on recipes */
.meta-cards {
	display: flex;
	justify-content: flex-end;
	flex-wrap: wrap;
}

.meta-card {
	min-width: 100px; /* Adjust the width as needed */
  min-height: 65px;
	margin: 10px;
	display: inline-flex;
  align-items: center;
  text-align: center;
	vertical-align: top; /* Ensure cards align properly */
}

.meta-card .card-body {
  padding: 10px;
  padding-bottom: 0;
}

/* Editable Text Indicator */
.editable {
  position: relative;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
}

.editable:hover:not(:has(input))::after {
  content: '\f303'; /* Unicode for the Font Awesome pencil icon */
  font-family: 'Font Awesome 6 Free';
  font-weight: 900; /* Required for solid icons */
  margin-left: 8px; /* Adjust the spacing as needed */
  color: #000; /* Adjust the color as needed */
  opacity: .5;
}

.editable-always:not(:has(input))::after {
  content: '\f303'; /* Unicode for the Font Awesome pencil icon */
  font-family: 'Font Awesome 6 Free';
  font-weight: 900; /* Required for solid icons */
  margin-left: 8px; /* Adjust the spacing as needed */
  color: #000; /* Adjust the color as needed */
  opacity: .5;
}

/* Launchable Text Indicator */

/* Editable Row Indicator */
.launchable {
  position: relative;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
}

.launchable:hover:not(:has(input))::after, .launchable_card:hover .card-header-title::after {
  content: '\f08e'; /* Unicode for the Font Awesome pencil icon */
  font-family: 'Font Awesome 6 Free';
  font-weight: 900; /* Required for solid icons */
  margin-left: 8px; /* Adjust the spacing as needed */
  color: #000; /* Adjust the color as needed */
  opacity: .5;
}

.launchable-always:not(:has(input))::after {
  content: '\f08e'; /* Unicode for the Font Awesome pencil icon */
  font-family: 'Font Awesome 6 Free';
  font-weight: 900; /* Required for solid icons */
  margin-left: 8px; /* Adjust the spacing as needed */
  color: #000; /* Adjust the color as needed */
  opacity: .5;
}

/* General table styling */
.draggable-table {
    width: 100%;
    border-collapse: collapse;
    background-color: #fff;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.12), 0px 1px 2px rgba(0, 0, 0, 0.24);
}

/* Header styling */
.draggable-table th {
    background-color: #f4f4f4;
    color: #333;
    padding: 12px 15px;
    text-align: left;
    font-size: 14px;
    font-weight: 600;
    border-bottom: 1px solid #eaeaea;
}

/* Body row styling */
.draggable-table td {
    padding: 12px 15px;
    border-bottom: 1px solid #eaeaea;
    font-size: 14px;
}

/* Alternating row colors */
.draggable-table tbody tr:nth-child(even) {
    background-color: #f9f9f9;
}

.draggable-table tbody tr:nth-child(odd) {
    background-color: #fff;
}

/* Row hover effect */
.draggable-table tbody tr:hover {
    background-color: #eaeaea;
}

/* Draggable row cursor */
.draggable-table tr[draggable="true"] {
    cursor: grab;
}

.draggable-table tr[draggable="true"]:active {
    cursor: grabbing;
}

/* Conditional row styling */
.bold-row {
    font-weight: bold;
    border-top: 2px solid #000;
}

.italic-row {
    font-style: italic;
}

.mouse-arrow {
	cursor: default;
}

.mouse-pointer {
  cursor: pointer;
}

.true-size-div {
  display: inline-block;
  margin: 5px;
}

/* Action Icons */
.action-icon {
  padding: 3px;
  margin: 2px;
  cursor: default;
}

/* Fade out */
.fade-out {
  animation: fadeOut 0.5s forwards;
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

/* Ingredients Page */
.new-item-modal.modal-dialog {
  max-width: 50vw;
}

.component-tabs {
  padding:0;
}
.component-tabs .rc-tabs-nav .rc-tabs-tab::before {
  content: "";
  border-radius: 15px;
  background: #545cd8;
  transition: all 0.2s;
  height: 4px;
  width: 100%;
  position: absolute;
  left: 0;
  bottom: -2px;
  opacity: 0;
}

/* Inventory Single Count */
.checkmark-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.checkmark-list li {
  position: relative;
  padding-left: 20px; /* Adjust padding to make space for the checkmark */
}

.checkmark-list li::before {
  content: '○'; /* Circle symbol */
  position: absolute;
  left: 0;
}

.btn.action {
  display: inline-block;
  padding: 5px 10px;
  font-size: 1em;
}


.btn-icon-vertical i.fa-solid {
  opacity: 1;
  color: #0d6efd;
}

/* Overall Section */
.features-section {
  text-align: center;
  padding-top: 100px;
  padding-bottom: 100px;
  background-color: #f9f9f9; /* Optional background color */
}

/* Container */
.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px; /* Add horizontal padding for smaller screens */
}

/* Section Title */
.section-title h2 {
  font-size: 2rem;
  margin-bottom: 20px;
  text-align: center;
}

/* Rows */
.row {
  display: flex;
  flex-wrap: wrap; /* Ensures wrapping for smaller screens */
}

/* Columns */
.column {
  padding: 10px; /* Add spacing between columns */
}

.column-4 {
  flex: 0 0 33%; /* 4/12 columns */
  max-width: 33%; /* Ensures proper width */
}

.column-8 {
  flex: 0 0 66%; /* 8/12 columns */
  max-width: 66%;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .column-4,
  .column-8 {
    flex: 0 0 100%; /* Full width on smaller screens */
    max-width: 100%;
    text-align: left; /* Align text to left for better readability */
  }
}

.logo-white-src {
  height: 120px;
  width: 360px;
  background: url('../../public/img/logo\ white\ blue.svg') no-repeat;
}

.public-footer {
  background-color: #242424;
  height: 400px;
  color: #fff;
  position: relative; /* Constrains the ::before pseudo-element to this container */
  overflow: hidden; /* Ensures the ::before pseudo-element doesn't overflow the footer */
  z-index: 2;
}
.public-footer > div {
  padding: 80px;
}
.public-footer .app-footer__inner {
  max-width: 1300px;
  margin: 0 auto;
}

.public-footer::before {
  content: '';
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-image: url('../assets/images/login_bg.png');
  background-repeat: no-repeat;
  background-position: -5% 107%;
  background-size: 30%;
  z-index: -1;
  opacity: .1;
  transform: scaleX(-1);
}

.public-footer .app-footer__inner > div {
  min-height: 255px;
}

.public-footer .footer-nav li {
  margin-bottom: 20px;
}

.public-footer .footer-nav a {
  color: white;
  text-decoration: none !important;
}

.public-footer .footer-nav-title {
  margin-bottom: 20px;
  font-size: 1.2rem;
  font-weight: bold;
}

/* Subscription Page Classes */
.subscription-details-wrapper {
  clear: both;
  margin: 30px 0;
  border-radius: 2rem;
  width: 100%;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);

  table {
    width: 100%; 
    border-collapse: collapse; 

    tr td {
      padding: 30px 40px;
      width: 50%;

      p {
        margin-bottom: 0;
      }
    }

    tr td.editable:hover {

    }

    tr td:not(:last-child) {
      border-right: 1px solid #e2e2e2; /* Add right border to all cells except the last one in each row */
    }
    
    tr:not(:last-child) td {
      border-bottom: 1px solid #e2e2e2; /* Add bottom border to all cells except the last row */
    }

    .cell-title {
      font-size: 1rem;
      color: #777;
      margin-bottom: 10px;
    }
    .cell-detail {
      font-size: 2rem;
    }
  }
}

#page-container {
  height: 100%;
}